import { cn } from '$app/utils'
import AppEnvironment from '$components/AppEnvironment'
import AppLogo from '$components/AppLogo'
import Loading from '$components/Loading'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { useApiListingParams, useFocusEventLister } from '$hooks/actions'
import { useAppService } from '$hooks/services'
import { useAppDetailStore } from '$hooks/stores'
import { redirect, ROUTE_NAMES } from '$router/config'
import { Spinner } from '@genie-fintech/ui/components'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { Icon } from '@genie-fintech/ui/icons'
import * as Dialog from '@radix-ui/react-dialog'
import { FormEvent, useCallback, useEffect, useRef, useState } from 'react'

const AppSwitcher = () => {
  const [showConfirm, setShowConfirm] = useState(false)

  const [activeIndex, setActiveIndex] = useState<number | null>(null)

  const {
    route: { params }
  } = useRouteSummary()

  const { appId } = params

  const { searchValue, updateSearchValue, debouncedSearchValue } =
    useApiListingParams()

  const cardRefs = useRef<(HTMLElement | null)[]>([])

  const { name, logo, is_published, environment, brand } =
    useAppDetailStore(state => state.appDetail) || {}

  const { fetchAppSelectListAsync, fetchingAppSelectList, app_option_list } =
    useAppService()

  useFocusEventLister(() => {
    setShowConfirm(true)
  })

  useEffect(() => {
    if (activeIndex === null) return
    cardRefs.current[activeIndex]?.scrollIntoView({
      behavior: 'smooth',
      block: 'center'
    })
  }, [activeIndex])

  useEffect(() => {
    if (!showConfirm || !appId) return

    fetchAppSelectListAsync({
      q: debouncedSearchValue,
      per_page: 10,
      application_id: appId
    }).then(({ data }) => {
      if (!data.length) return
      setActiveIndex(0)
    })
  }, [showConfirm, appId, fetchAppSelectListAsync, debouncedSearchValue])

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'ArrowUp') {
        setActiveIndex(prevIndex =>
          prevIndex === null || prevIndex === 0
            ? app_option_list.length - 1
            : prevIndex - 1
        )
      } else if (event.key === 'ArrowDown') {
        setActiveIndex(prevIndex =>
          prevIndex === null || prevIndex === app_option_list.length - 1
            ? 0
            : prevIndex + 1
        )
      }
    },
    [app_option_list]
  )

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  const handleOnClose = useCallback(() => {
    setShowConfirm(false)
  }, [])

  const handleOnClickSwitcher = useCallback(() => {
    setShowConfirm(true)
  }, [])

  const handleOnClickCard = useCallback(
    (appId: number) => {
      redirect(ROUTE_NAMES.APP_HOME, { params: { appId } })
      handleOnClose()
    },
    [handleOnClose]
  )

  const handleOnEnter = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      if (!app_option_list.length || activeIndex === null) return
      handleOnClickCard(app_option_list[activeIndex].id)
    },
    [app_option_list, activeIndex, handleOnClickCard]
  )

  return (
    <Dialog.Root open={showConfirm}>
      <button
        className="flex items-center gap-x-2 w-full border border-[--colors-neutral-10] bg-[--colors-area-high] rounded-xl p-2 shadow-[0px_1px_2px_1px] shadow-[--colors-alphaNeutral-1]"
        onClick={handleOnClickSwitcher}
      >
        <AppLogo logo={logo} is_published={is_published} brand={brand} />

        <article className="flex-1 flex flex-col items-start text-sm text-[--colors-text-neutral] font-medium truncate">
          {!name && <Spinner />}

          {name && (
            <>
              {name}
              {environment && <AppEnvironment environment={environment} />}
            </>
          )}
        </article>
        <Icon namespace="UpDown" width={18} />
      </button>
      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" onClick={handleOnClose} />
        <Dialog.Content
          className="DialogContent max-w-2xl !bg-[--colors-area-medium] h-[500px]"
          aria-describedby=""
        >
          {fetchingAppSelectList && (
            <article className="flex-1 grid place-items-center">
              <Loading />
            </article>
          )}

          <article className="flex flex-col p-0 h-[500px] overflow-y-auto overflow-x-hidden relative no-scrollbar">
            <Dialog.DialogTitle className="sticky top-0 z-10">
              <form
                className="flex px-4 py-2 bg-[--colors-area-high]"
                onSubmit={handleOnEnter}
              >
                <BaseText
                  elementControlProps={{
                    className: 'border-transparent flex py-2 gap-x-2'
                  }}
                  affix={{
                    pre: <Icon namespace="Search" width={16} />,
                    post: searchValue ? (
                      <button
                        type="button"
                        onClick={() => updateSearchValue('')}
                      >
                        <Icon namespace="Cross" width={18} />
                      </button>
                    ) : undefined
                  }}
                  inputProps={{
                    placeholder: 'Enter App Name',
                    value: searchValue,
                    onChange: e => updateSearchValue(e.currentTarget.value)
                  }}
                />
              </form>
            </Dialog.DialogTitle>

            <article className="flex flex-col gap-2 px-4 py-2 flex-1">
              {!app_option_list.length && !fetchingAppSelectList && (
                <article className="flex items-center justify-center flex-1 text-[--colors-neutral-50]">
                  App Not Found!
                </article>
              )}

              {app_option_list.map((v, k) => {
                const isActive = k === activeIndex

                return (
                  <article
                    key={k}
                    className={cn(
                      'flex flex-col cursor-pointer gap-2 rounded-lg hover:bg-[--colors-neutral-10] duration-200',
                      isActive
                        ? 'border-2 border-[--colors-primary-80]'
                        : 'border-2 border-[--colors-neutral-10]'
                    )}
                    ref={el => (cardRefs.current[k] = el)}
                    onClick={() => {
                      handleOnClickCard(v.id)
                    }}
                  >
                    <article className="flex items-center gap-2 p-4 bg-[--colors-area-high] rounded-lg">
                      <AppLogo
                        is_published={v.is_published}
                        logo={v.logo}
                        brand={v.brand}
                      />

                      <article className="flex-1 flex flex-col">
                        <p className="text-sm font-medium truncate">{v.name}</p>

                        <AppEnvironment environment={v.environment} />
                      </article>

                      <article className="flex gap-1 items-center text-[--colors-text-light]">
                        <span className="text-sm font-medium">Switch</span>
                        <Icon namespace="Forward" width={18} />
                      </article>
                    </article>
                  </article>
                )
              })}
            </article>
          </article>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default AppSwitcher
