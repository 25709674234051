import { cn } from '$app/utils'
import { Logo } from '$assets/svg'
import Profile from '$components/Profile'
import SidebarToggleMenu from '$components/SideBarToggleMenu'
import { onOneOf, ROUTE_NAMES } from '$router/config'

import { useTheme } from '@genie-fintech/ui/hooks'
import { Icon } from '@genie-fintech/ui/icons'
import { FC, PropsWithChildren } from 'react'
import { NavMenus } from './constants'
import Link from '$router/Link'

const PageLayout: FC<PropsWithChildren<{ removeLink?: boolean }>> = ({
  children,
  removeLink
}) => {
  const {
    mode: { isDarkMode },
    toggleTheme
  } = useTheme()

  const { Icon: MenuIcon, name } =
    NavMenus.find(d => onOneOf([d.to, ...d.subPath])) ?? {}

  return (
    <section className="flex flex-col min-h-screen">
      <nav
        className={cn(
          'flex sticky top-0 z-10 items-center px-6 py-1.5 h-[56px]',
          'bg-[hsl(0,0%,6%)]'
        )}
      >
        {!removeLink && <SidebarToggleMenu />}

        <Link to={ROUTE_NAMES.APPS}>
          <article className="flex text-[--colors-absolute-light] px-4">
            <Logo />
          </article>
        </Link>

        <article className="flex gap-1 items-center text-[--colors-absolute-light]">
          {MenuIcon && <MenuIcon size={16} />}
          <span className="text-sm font-medium">{name}</span>
        </article>

        <article className="flex-1" />

        <article className="flex items-center gap-x-2">
          <button
            type="button"
            className={cn(
              'flex items-center justify-center border border-[--colors-alphaNeutral-2] rounded-full w-[32px] h-[32px] duration-200 bg-[hsl(226,14%,23%)]'
            )}
            onClick={toggleTheme}
          >
            <Icon
              namespace={isDarkMode ? 'Dark' : 'Light'}
              color="absolute.light"
              width={18}
            />
          </button>

          <Profile />
        </article>
      </nav>

      <main className="grow flex flex-col xl:container xl:mx-auto px-4 py-2 overflow-x-auto">
        {children}
      </main>
    </section>
  )
}

export default PageLayout
