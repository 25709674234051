import { ChangeEvent, useCallback, useRef, useState } from 'react'
import * as Dialog from '@radix-ui/react-dialog'
import { Button, Checkbox, Spinner } from '@genie-fintech/ui/components'
import { Info, Trash2 } from 'lucide-react'
import { BaseText } from '@genie-fintech/ui/components/fields'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useRouteSummary } from '$contexts/RouteContext/hooks'
import { toast } from 'sonner'
import { redirect } from '$router/config'
import { capitalize } from 'lodash-es'
import { CategoryData, TCategory } from './constants'
import { useDeleteService } from '$hooks/services'

interface IProps {
  type: TCategory
  name: string
}

const DeleteWithConfirmInput = ({ type, name: itemName }: IProps) => {
  const [dialogOpen, setDialogOpen] = useState(false)

  const [step, setStep] = useState(1)

  const [checked, setChecked] = useState<CheckedState>(false)

  const inputRef = useRef<HTMLInputElement | null>(null)

  const [inputValue, setInputValue] = useState('')

  const {
    route: { params }
  } = useRouteSummary()

  const { deleteItemByAppIdAsync, deletingItemByAppId } = useDeleteService()

  const handleOnCancel = useCallback(() => {
    setDialogOpen(false)
    setStep(1)
  }, [])

  const handleOpenDialog = useCallback(() => {
    setDialogOpen(true)
  }, [])

  const handleOnInputChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.currentTarget.value)
    },
    []
  )

  const handleOnStepOneDelete = useCallback(() => {
    setStep(2)
  }, [])

  const {
    title = 'DELETE',
    primaryBtnText = 'Delete',
    redirectPath
  } = CategoryData[type]

  const desc = (() => {
    const first = `${type === 'user' ? 'Terminating' : 'Deleting'} the ${type} is an irreversible action.`
    const second =
      type === 'user' ? '' : 'All data will be erased and cannot be retrieved.'
    return [first, second].filter(Boolean).join(' ')
  })()

  const onFinalDelete = useCallback(() => {
    if (!params.appId) {
      toast.error('Invalid Application ID!')
      return
    }

    let url = `/applications/${params.appId}`

    const itemId = (() => {
      switch (type) {
        case 'group':
          return params.groupId
        case 'user':
          return params.userId
        case 'role':
          return params.roleId
        case 'permission':
          return params.permissionId
        default:
          return undefined
      }
    })()

    if (type !== 'application' && itemId) {
      url += `/${type}s/${itemId}`
    }

    if (type !== 'application' && !itemId) {
      toast.error('Invalid Item ID!')
      return
    }

    deleteItemByAppIdAsync({ url }).then(() => {
      toast.success(
        `${capitalize(type)} has been ${type === 'user' ? 'terminated' : 'deleted'}!`
      )
      redirect(redirectPath, { params })
    })
  }, [params, type, deleteItemByAppIdAsync, redirectPath])

  const isSameInput = inputValue.trim() === itemName

  return (
    <Dialog.Root open={dialogOpen}>
      <article className="flex bg-[--colors-alphaDanger-0] px-5 py-3 rounded-lg items-center">
        <article className="flex flex-col gap-y-1 flex-1 px-3">
          <p className="font-semibold">{title}</p>
          <p className="text-xs text-[--colors-neutral-50]">{desc}</p>
        </article>

        <Button styleVariants={{ kind: 'danger' }} onClick={handleOpenDialog}>
          <span>{primaryBtnText}</span>
          <Trash2 size={16} />
        </Button>
      </article>

      <Dialog.Portal>
        <Dialog.Overlay className="DialogOverlay" />
        <Dialog.Content
          aria-describedby=""
          className="DialogContent max-w-lg p-5"
        >
          <Dialog.Title></Dialog.Title>

          {step === 1 && (
            <article className="flex flex-col gap-y-4">
              <p className="text-xl font-semibold">Are you sure?</p>

              <p className="text-sm text-[--colors-neutral-70]">{desc}</p>

              <footer className="flex justify-end items-center gap-x-2">
                <Button
                  styleVariants={{ type: 'outlined' }}
                  onClick={handleOnCancel}
                >
                  Cancel
                </Button>

                <Button
                  styleVariants={{ kind: 'danger' }}
                  onClick={handleOnStepOneDelete}
                >
                  <span>Delete</span>
                  <Trash2 size={16} />
                </Button>
              </footer>
            </article>
          )}

          {step === 2 && (
            <article className="flex flex-col gap-y-4">
              <article className="flex items-center px-6 py-4 gap-x-1 text-[--colors-neutral-80] bg-[--colors-alphaDanger-0] rounded border border-[--colors-danger-default]">
                <Info size={18} />
                <span className="text-sm font-medium ">
                  Are you sure you want to{' '}
                  {type === 'user' ? 'terminate' : 'delete'} the {type}?
                </span>
              </article>

              <article className="flex flex-col">
                <p className="font-semibold">{title}</p>
                <p className="text-xs text-[--colors-neutral-50]">{desc}</p>
              </article>

              <article className="flex flex-col gap-y-1">
                <label className="inline-flex gap-x-1 items-center text-sm font-medium">
                  <span>Type {type} name "</span>
                  <span className="text-[--colors-danger-default]">
                    {itemName}
                  </span>
                  <span>
                    " to proceed {type === 'user' ? 'termination' : 'deletion'}
                  </span>
                  <span className="text-[--colors-danger-default]">*</span>
                </label>

                <BaseText
                  inputRef={inputRef}
                  inputProps={{
                    value: inputValue,
                    onChange: handleOnInputChange
                  }}
                  message={'Enter the exact phrase (case sensitive).'}
                />
              </article>

              <article>
                <Checkbox
                  label="I understand all data will be lost if I delete the application"
                  styleVariants={{ fullWidth: true }}
                  boxProps={{ checked, onCheckedChange: setChecked }}
                />
              </article>

              <footer className="flex justify-end items-center gap-x-2">
                <Button
                  styleVariants={{ type: 'outlined' }}
                  onClick={handleOnCancel}
                >
                  Cancel
                </Button>

                <Button
                  styleVariants={{ kind: 'danger' }}
                  onClick={onFinalDelete}
                  disabled={!checked || deletingItemByAppId || !isSameInput}
                >
                  <span>{type === 'user' ? 'Terminate' : 'Delete'}</span>
                  {deletingItemByAppId ? <Spinner /> : <Trash2 size={16} />}
                </Button>
              </footer>
            </article>
          )}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  )
}

export default DeleteWithConfirmInput
